import * as finalform from 'final-form';

export * from 'react-final-form';
export const {
  createForm,
  fieldSubscriptionItems,
  formSubscriptionItems,
  ARRAY_ERROR,
  FORM_ERROR,
  getIn,
  setIn,
  configOptions,
  version: finalFormVersion,
} = finalform;
